import { createContext, useCallback, useEffect, useState } from "react";
import axiosInstance from "../api/api_instance";
import { getUserApi } from "../api/user.api";
import {
  cleanUserInLocalStorage,
  getUserInLocalStorage,
  setUserInLocalStorage,
} from "../services/authServices";
import {
  cleanCachingUserInLocalStorage,
  getCachingUserInLocalStorage,
  setCachingUserInLocalStorage,
} from "../services/cachingProfileRequest";
import { useRouter } from "next/router";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  const router = useRouter();
  useEffect(() => {
    if (getUserInLocalStorage()) {
      setUser(getUserInLocalStorage());
      getUserProfile();
    } else {
      setIsLoadingAuth(false);
    }
  }, []);

  const getUserProfile = useCallback(async () => {
    // const userCaching = getCachingUserInLocalStorage();

    // if (userCaching) {
    //   setUser(userCaching);
    //   setIsLoadingAuth(false);
    //   return;
    // }

    try {
      if (!isLoadingAuth) {
        setIsLoadingAuth(true);
      }
      const userResponse = await getUserApi();
      setUserInLocalStorage(userResponse?.data);
      setCachingUserInLocalStorage(userResponse?.data);
      setUser(userResponse?.data);
      setIsLoadingAuth(false);
    } catch (error) {
      setIsLoadingAuth(false);
    }
  }, [isLoadingAuth]);

  const signOut = () => {
    axiosInstance.setToken("");
    setUser(null);
    cleanUserInLocalStorage();
    cleanCachingUserInLocalStorage();
    router.replace("/");
  };

  const updateUserInfoLocal = (user) => {
    setUserInLocalStorage(user);
    setCachingUserInLocalStorage(user);
    setUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        getUserProfile,
        signOut,
        updateUserInfoLocal,
        isLoadingAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
