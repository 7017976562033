import Image from "next/image";
import { useRouter } from "next/router";
import React, { memo, useContext, useState } from "react";
import { ArrowDownHeaderIcon, IconClose, MenuIcon } from "../../constant/icon";
import BaseImage from "../baseImage/BaseImage";
import BaseButton from "../button/BaseButton";
import MenuChangeLanguage from "./MenuChangeLanguage";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import ButtonSignOut from "./ButtonSignOut";
import { OPERATOR_ROLE } from "../../constant/role";
function Header() {
  const { t } = useTranslation();

  const { user, signOut, isLoadingAuth } = useContext(AuthContext);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const onOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const router = useRouter();
  const onRedirectToHome = () => {
    router.push("/");
  };
  const onRedirectLogin = () => {
    router.push("/login");
  };
  const onRedirectRegister = () => {
    router.push("/register");
  };

  if (isLoadingAuth) return;

  return (
    <div className="fixed top-0 z-50 w-full text-center bg-white shadow-menu lg:shadow-none ">
      <div className="h-[85px] w-full  px-4 py-6 flex justify-between items-center lg:px-20 sm:px-10">
        <div className="flex items-center h-8 gap-3 lg:justify-between lg:pr-10 focus:ring-2 focus:outline hover:cursor-pointer">
          {isOpenMenu ? (
            <div className="lg:hidden" onClick={onOpenMenu}>
              <IconClose />
            </div>
          ) : (
            <div className=" lg:hidden" onClick={onOpenMenu}>
              <MenuIcon />
            </div>
          )}

          <Link href={"/"}>
            <Image
              src={"/images/locate_logo_new.png"}
              width={170}
              height={44}
              alt="locate_logo_new"
              // className="w-40 h-10"
              // onClick={onRedirectToHome}
            />
          </Link>
        </div>
        <div className="flex items-center justify-end ">
          {user && (
            <Link href={"/my-ticket"}>
              <p className="hidden font-normal min-w-fit lg:text-body-1 lg:px-4 line-clamp-1 text-body-1 text-neutral-700 lg:block">
                {t("my_ticket")}
              </p>
            </Link>
          )}
          {user && OPERATOR_ROLE.includes(user?.roles) && (
            <Link href={"/event-management"}>
              <p className="hidden font-normal min-w-fit lg:text-body-1 lg:px-4 line-clamp-1 text-body-1 text-neutral-700 lg:block">
                {t("event_management")}
              </p>
            </Link>
          )}
          <div
            className={`h-10  border-neutral-200 ${user ? "lg:border-l" : ""}`}
          />
          <div className="flex items-center gap-6 pl-4 border-anti_flash_white rtl:pr-4">
            <div className="hidden font-normal lg:block">
              <MenuChangeLanguage />
            </div>
            {!user ? (
              <>
                {/* <div className="font-bold ">
                  <BaseButton
                    className="hidden px-8 py-2 border lg:block"
                    onClick={onRedirectRegister}
                    sizeButton="sm"
                  >
                    <p className="font-bold text-white text-body-2">
                      {t("Register")}
                    </p>
                  </BaseButton>
                </div> */}
                <div className="font-bold ">
                  <BaseButton
                    className="px-8 py-2"
                    onClick={onRedirectLogin}
                    variant="outline"
                    sizeButton="sm"
                  >
                    <p className="font-bold text-body-2 text-primary-200">
                      {t("Login")}
                    </p>
                  </BaseButton>
                </div>
              </>
            ) : (
              <div>
                {isOpenMenu ? (
                  <button
                    onClick={() => router.push("/profile-user-setting")}
                    className="items-center hidden gap-2 md:flex"
                  >
                    <div className="w-10 h-10">
                      <BaseImage
                        className="rounded-full"
                        backgroundSize="cover"
                        src={
                          user?.avatar
                            ? user?.avatar
                            : "/images/logoUserMale.png"
                        }
                      />
                    </div>
                    <p className="hidden font-bold capitalize text-body-1 line-clamp-1 text-neutral-700 sm:block">
                      {user?.fullName}
                    </p>
                    {isOpenMenu ? null : (
                      <div className="hidden sm:block">
                        <ArrowDownHeaderIcon />
                      </div>
                    )}
                  </button>
                ) : (
                  <div className="flex flex-row items-center gap-x-2">
                    <button
                      onClick={() => router.push("/profile-user-setting")}
                      className="flex items-center gap-2"
                    >
                      <div className="w-10 h-10">
                        <BaseImage
                          className="rounded-full"
                          backgroundSize="cover"
                          src={
                            user?.avatar
                              ? user?.avatar
                              : "/images/logoUserMale.png"
                          }
                        />
                      </div>
                      <p className="hidden font-bold capitalize min-w-fit text-body-1 text-neutral-700 line-clamp-1 sm:block">
                        {user?.fullName}
                      </p>
                    </button>

                    <ButtonSignOut />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isOpenMenu && (
        <div className="flex flex-col gap-8 mx-4 mt-2 mb-6 lg:hidden">
          {user && (
            <button
              onClick={() => {
                setIsOpenMenu(false);
                router.push("/profile-user-setting");
              }}
              className="flex items-center gap-2 md:hidden"
            >
              <div className="w-8 h-8">
                <BaseImage
                  className="rounded-full"
                  backgroundSize="cover"
                  src={user?.avatar ? user?.avatar : "/images/logoUserMale.png"}
                />
              </div>
              <p className="font-bold text-body-2 text-neutral-700">
                {user?.fullName}
              </p>
            </button>
          )}

          <div className="flex flex-col gap-6">
            {user && (
              <div className="flex flex-col gap-6 ">
                <div className="w-fit">
                  <Link
                    href={"/my-ticket"}
                    onClick={() => {
                      setIsOpenMenu(false);
                    }}
                  >
                    <p className="text-left text-body-1 text-neutral-700 w-fit">
                      {t("my_ticket")}
                    </p>
                  </Link>
                </div>
                {OPERATOR_ROLE.includes(user?.roles) && (
                  <div className="w-fit">
                    <Link
                      href={"/event-management"}
                      onClick={() => {
                        setIsOpenMenu(false);
                      }}
                    >
                      <p className="text-left text-body-1 text-neutral-700 w-fit">
                        {t("event_management")}
                      </p>
                    </Link>
                  </div>
                )}

                <button
                  onClick={signOut}
                  type="button"
                  className="text-left text-body-1 text-neutral-700 w-fit"
                >
                  {t("logout")}
                </button>
              </div>
            )}
            <MenuChangeLanguage />
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(Header);
