import axios from "axios";
import { API_VERSION, BASE_URL } from "../constant/server";
import { setToken } from "../services/authServices";

export const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem("accessToken")).accessToken;
  } catch (error) {
    return null;
  }
};

const prepareBearerToken = () => {
  if (getToken()) {
    return `bearer ${getToken()}`;
  }
  return "";
};

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}${API_VERSION}`,
  headers: {
    Authorization: prepareBearerToken(),
    "Content-Type": "application/json",
    timeout: 10000,
  },
});
axiosInstance.setToken = (token) => {
  axiosInstance.defaults.headers["Authorization"] = `bearer ${token}`;
  setToken(token);
};

export default axiosInstance;
