import { TEXT_COLOR_TYPE, TEXT_SIZE_TYPE } from "../../utils/componentsType";

export const TEXT_SIZE = {
  "2xl": "text-title-1", //24
  xl: "text-title-2", //20
  lg: "text-lg", //18
  md: "text-body-1", //16
  sm: "text-body-2", //14
  xs: "text-body-3", //12
  "3xl": "text-3xl", //12
};
export const TEXT_COLOR = {
  white: "white", //12
  "primary-100": "text-primary-100",
  "primary-200": "text-primary-200",
  "primary-500": "text-primary-500",
  "secondary-300": "text-secondary-300",
  "secondary-400": "text-secondary-400",
  "fourth-100": "text-fourth-100",
  "fourth-200": "text-fourth-200",
  "fourth-600": "text-fourth-600",
  "fourth-700": "text-fourth-700",
};

type TextBaseType = {
  children: any;
  className?: any;
  size: TEXT_SIZE_TYPE;
  color: TEXT_COLOR_TYPE;
  textClass?: any;
};
export default function TextBaseV2({
  children,
  className,
  size = "xs",
  color = "primary-100",
  textClass,
}: TextBaseType) {
  return (
    <div
      className={`${textClass} ${TEXT_SIZE[size]} ${TEXT_COLOR[color]} ${className} `}
    >
      {children}
    </div>
  );
}
