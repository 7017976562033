import { ReactElement } from "react";
import { LoadingIcon } from "../../constant/icon";
import { BUTTON_SIZE_TYPE } from "../../utils/componentsType";
import TextBaseV2 from "../textBaseV2/TextBaseV2";
import React from "react";

type BUTTON_VARIANT = "filled" | "outline";

const SIZE_BUTTON = {
  xl: "px-6 py-3.5",
  lg: "px-5 py-3",
  md: "px-5 py-2.5",
  sm: "px-3 py-2",
  xs: "px-3 py-2",
  small: "px-1 py-1 md:px-2 md:py-2",
};
const COLOR_BUTTON = {
  filled: {
    normal: "bg-primary-200 text-white",
    hover: "hover:bg-primary-100 ",
    active: "active:ring-2 active:ring-primary-300",
    disable: "border-secondary-800 bg-gray-300 cursor-not-allowed",
    visible: "border-primary-200",
  },
  outline: {
    normal: "bg-transparent border text-primary-200 border-primary-200",
    hover:
      "hover:bg-transparent hover:border hover:border-primary-100 hover:text-primary-100",
    active:
      "active:ring-2 active:ring-primary-400 active:bg-primary-400 active:text-primary-200",
    disable:
      "disabled:border-secondary-800 disabled:bg-secondary-900 cursor-not-allowed",
    visible: "border-primary-200",
  },
};

type BASE_BUTTON_TYPE = {
  title: string;
  disable: any;
  onClick: any;
  isLoading: boolean;
  sizeButton: BUTTON_SIZE_TYPE;
  variant: BUTTON_VARIANT;
  className: string;
  children: ReactElement;
  textClass: string;
  type?: "button" | "submit" | "reset";
  rightIcon?: any;
};

export default function BaseButton({
  title,
  disable = false,
  onClick,
  isLoading = false,
  sizeButton = "small",
  variant = "filled",
  className,
  textClass,
  children,
  type,
  rightIcon,
}: BASE_BUTTON_TYPE) {
  const handleClick = () => {
    if (disable) return;
    if (typeof onClick === "function") onClick();
  };

  const classButtonByStatus = buildStyleButton({
    sizeButton,
    variant,
    disable,
  });

  return (
    <div className={"w-full h-full relative "}>
      <button
        type={type || "button"}
        disabled={disable}
        onClick={handleClick}
        className={`${className} w-full h-full flex justify-center items-center rounded-lg whitespace-nowrap ${classButtonByStatus}`}
      >
        {children && children}
        {title && (
          <TextBaseV2 color="white" textClass={textClass} size={sizeButton}>
            {title}
          </TextBaseV2>
        )}
        {typeof rightIcon === "function" && rightIcon()}
      </button>
    </div>
  );
}
const buildStyleButton = ({
  variant,
  sizeButton,
  disable = false,
}: {
  variant: BUTTON_VARIANT;
  sizeButton: BUTTON_SIZE_TYPE;
  disable: boolean;
}) => {
  return `${SIZE_BUTTON[sizeButton]} ${
    !disable && COLOR_BUTTON[variant].normal
  } ${!disable && COLOR_BUTTON[variant].hover} ${
    !disable ? COLOR_BUTTON[variant].active : COLOR_BUTTON[variant].disable
  }`;
};
