import Image from "next/image";
import React from "react";

export default function BaseImage({
  height,
  width,
  src,
  imageStyle,
  backgroundSize,
  className,
  ...props
}) {
  const getImageName = () => {
    if (!src) return "";
    const filename = src.split("/").pop();
    return filename ? filename : "";
  };

  if (!src) return null;
  return (
    <div className={`${className} relative w-full h-full  overflow-hidden `}>
      <Image
        {...props}
        alt={getImageName()}
        blurDataURL={src}
        src={src}
        fill
        sizes="100%"
        style={{
          objectFit: backgroundSize || "contain",
        }}
      />
    </div>
  );
}
