import React from "react";
import { useTranslation } from "react-i18next";
import Link from "next/link";
import TextBaseV2 from "../textBaseV2/TextBaseV2";
import Image from "next/image";

//TODO: change link of pages
const socialIcons = [
  {
    id: "1",
    source: "/icons/facebook_icon.svg",
    alt: "facebook_icon",
    link: "/about-us",
  },
  {
    id: "2",
    source: "/icons/instagram_icon.svg",
    alt: "instagram_icon",
    link: "/about-us",
  },
  {
    id: "3",
    source: "/icons/linkedin_icon.svg",
    alt: "linkedin_icon",
    link: "/about-us",
  },
  {
    id: "4",
    source: "/icons/twitter_icon.svg",
    alt: "twitter_icon",
    link: "/about-us",
  },
];

const useLinksArray = () => {
  const { t } = useTranslation();
  const linksArray = [
    // {
    //   id: "1",
    //   link: "/about-us",
    //   text: t("schedule"),
    // },
    // {
    //   id: "2",
    //   link: "/about-us",
    //   text: t("speakers"),
    // },
    // {
    //   id: "3",
    //   link: "/about-us",
    //   text: t("ticket"),
    // },
    {
      id: "4",
      link: "/about-us",
      text: t("contact"),
    },
    {
      id: "5",
      link: "/privacy-policy",
      text: t("policy_privacy"),
    },
    {
      id: "6",
      link: "/terms-and-conditions",
      text: t("term_of_use"),
    },
    {
      id: "7",
      link: "/delivery-and-shipping-policy",
      text: t("delivery_and_shipping_policy"),
    },
    {
      id: "8",
      link: "/refund-policy",
      text: t("refund_policy"),
    },
  ];
  return { linksArray };
};

export default function Footer() {
  const { t } = useTranslation();
  const { linksArray } = useLinksArray();

  return (
    <div className="flex flex-col w-full pt-10 pb-6 sm:px-4 lg:px-20 bg-fourth-500">
      <div className="flex flex-col items-center border-b border-b-[#DEDCE0]">
        <Image
          src={"/images/locate_logo_new.png"}
          width={170}
          height={44}
          alt={"locate logo new"}
        />

        <div className="grid flex-row grid-cols-3 mt-6 mb-6 md:justify-center md:flex md:w-full sm:gap-x-10 gap-y-4 md: lg:mb-10">
          {linksArray.map((item) => {
            return (
              <div className="grid px-2 text-center " key={item.id}>
                <Link href={item.link} legacyBehavior>
                  <a>
                    <TextBaseV2 size={"sm"} color={"fourth-100"}>
                      {item.text}
                    </TextBaseV2>
                  </a>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col items-center justify-between py-6 sm: lg:flex-row">
        <TextBaseV2 size={"sm"} color={"fourth-200"}>
          {t("copyright")}
        </TextBaseV2>

        <div className="flex flex-row items-center mt-6 sm: lg:mt-0 gap-x-4">
          {socialIcons.map((item) => {
            return (
              <Link key={item.id} href={item.link} legacyBehavior>
                <a>
                  <Image
                    src={item.source}
                    alt={item.alt}
                    width={24}
                    height={24}
                  />
                </a>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
