import { remove } from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getImageBannerApi, getSettings } from "../api/home.api";
import { delayMs } from "../utils/delay";

export const DataContext = createContext({});

const DataContextProvider = ({ children }) => {
  const [ticketUserBuy, setTicketUserBuy] = useState([]);
  const [imagesBanner, setImagesBanner] = useState([]);
  const [isLoadingImagesBanner, setLoadingImagesBanner] = useState(true);
  const [settings, setSettings] = useState([]);

  const [currentOrder, setCurrentOrder] = useState(null);

  const onFetchDataBanner = useCallback(async () => {
    try {
      const response = await getImageBannerApi();
      setImagesBanner(response.data);
      delayMs(350).then(() => {
        setLoadingImagesBanner(false);
      });
    } catch (error) {
      delayMs(350).then(() => {
        setLoadingImagesBanner(false);
      });
    }
  }, []);

  const onFetchDataSetting = useCallback(async () => {
    try {
      const response = await getSettings();

      setSettings(response.data);

      delayMs(350).then(() => {
        setLoadingImagesBanner(false);
      });
    } catch (error) {
      delayMs(350).then(() => {
        setLoadingImagesBanner(false);
      });
    }
  }, []);

  useEffect(() => {
    onFetchDataBanner();
    onFetchDataSetting();
  }, [onFetchDataBanner, onFetchDataSetting]);

  const onResetData = useCallback(() => {
    setTicketUserBuy([]);
    setCurrentOrder(null);
  }, []);
  const onSetTicketUserBuy = useCallback(
    (newTicket) => {
      setTicketUserBuy([...ticketUserBuy, newTicket]);
    },
    [ticketUserBuy]
  );

  const onInCreaseTicket = useCallback(
    (indexUpdate) => {
      const newTickets = [...ticketUserBuy];
      newTickets[indexUpdate].quantity += 1;
      setTicketUserBuy(newTickets);
    },
    [ticketUserBuy]
  );
  const onDeCreaseTicket = useCallback(
    (indexUpdate) => {
      const newTickets = [...ticketUserBuy];
      if (newTickets[indexUpdate].quantity === 1) return;
      newTickets[indexUpdate].quantity -= 1;
      setTicketUserBuy(newTickets);
    },
    [ticketUserBuy]
  );

  const onDeleteTicket = useCallback(
    (indexDelete) => {
      //
      var newTickets = remove(ticketUserBuy, (_, i) => i !== indexDelete);
      setTicketUserBuy(newTickets);
    },
    [ticketUserBuy]
  );

  const calculateDiscount = (discountType, discountAmount, itemFee) => {
    let amount = 0;
    switch (discountType) {
      case "FIXED_AMOUNT":
        amount += discountAmount;
        break;
      case "PERCENTAGE":
        amount += (itemFee * discountAmount) / 100;
        break;
      default:
        break;
    }
    return itemFee - amount > 0 ? itemFee - amount : 0;
  };

  const totalPriceTicket = useMemo(() => {
    return ticketUserBuy?.reduce((preValue, currentValue) => {
      const totalPrice = currentValue?.coupon
        ? calculateDiscount(
            currentValue?.coupon?.discountType,
            currentValue?.coupon?.discountAmount,
            +currentValue?.price 
          )* +currentValue?.quantity
        : +currentValue?.quantity * +currentValue?.price;
      return (preValue += totalPrice);
    }, 0);
  }, [ticketUserBuy]);

  const dataTicketForCreateOrder = useMemo(() => {
    return ticketUserBuy?.reduce((preValue, currentValue) => {
      preValue.push({
        ticketTypeId: currentValue?.id,
        quantity: currentValue?.quantity,
        couponId: currentValue?.coupon?.id,
      });
      return preValue;
    }, []);
  }, [ticketUserBuy]);

  return (
    <DataContext.Provider
      value={{
        ticketUserBuy,
        onSetTicketUserBuy,
        onInCreaseTicket,
        onDeCreaseTicket,
        onDeleteTicket,
        totalPriceTicket,
        dataTicketForCreateOrder,
        currentOrder,
        setCurrentOrder,
        onResetData,
        setTicketUserBuy,
        imagesBanner,
        isLoadingImagesBanner,
        settings,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
