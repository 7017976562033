import axiosInstance from "./api_instance";
export const getClientHomeApi = ({ params }) => {
  return axiosInstance.get("client/home", {
    params: {
      ...params,

      ...(params?.time ? { time: JSON.stringify(params?.time) } : {}),
    },
  });
};

export const getCategoryClientHomeApi = () => {
  return axiosInstance.get(
    "client/sub-categories?limit=100&page=1&select=true"
  );
};

export const getEventsApi = ({ params }) => {
  return axiosInstance.get("client/events", { params });
};
export const getImageBannerApi = () => {
  return axiosInstance.get("client/sliders");
};

// new API
export const getPlaces = () => {
  return axiosInstance.get("places");
};

export const getSettings = () => {
  return axiosInstance.get("settings");
};
