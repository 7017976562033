import { Fragment, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import React from "react";
import { ArrowDownHeaderIcon, EnglishLogo } from "../../constant/icon";
import { useRouter } from "next/router";
import TextBaseV2 from "../textBaseV2/TextBaseV2";
import { useTranslation } from "react-i18next";

const languages = [
  { id: 1, key: "en", value: "ENG" },
  { id: 2, key: "ar", value: "عربي" },
];

export default function MenuChangeLanguage() {
  const defaultLanguageObj = useMemo(() => {
    const currentLanguage =
      typeof window !== "undefined" && localStorage?.getItem("language")?.length
        ? localStorage.getItem("language")
        : "ar";

    if (currentLanguage === "en") {
      return languages[0];
    }

    return languages[1];
  }, []);

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguageObj);
  const router = useRouter();
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language) => {
    router.push(router.asPath, undefined, { locale: language.key });
    i18n.changeLanguage(language.key);
    localStorage.setItem("language", language.key);

    setSelectedLanguage(language);
  };
  useEffect(() => {
    let dir = selectedLanguage?.key == "ar" ? "rtl" : "ltr";
    document.querySelector("html").setAttribute("dir", dir);
    document.querySelector("html").setAttribute("lang", selectedLanguage.key);
  }, [selectedLanguage]);

  return (
    <>
      <Listbox value={selectedLanguage.value} onChange={handleChangeLanguage}>
        <div className="relative mt-1">
          <Listbox.Button className="flex items-center w-full gap-2 hover:cursor-pointer">
            <p
              className="text-body-1 text-neutral-700"
              color="fourth-600"
              size="md"
            >
              {selectedLanguage.value}
            </p>
            <ArrowDownHeaderIcon />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-3 overflow-auto bg-white rounded-md shadow-lg text-body-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
              {languages.map((language) => (
                <Listbox.Option
                  key={language.id}
                  className={
                    "relative hover:cursor-pointer hover:bg-primary-200 select-none hover:text-white text-fourth-600 justify-center items-center px-4 py-2"
                  }
                  value={language}
                >
                  <p>{language.value}</p>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}
