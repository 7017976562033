import { isEmpty } from "lodash";
import { fromNowDateWithLocal, newDateWithTimeZone } from "../utils/date";
import { DATE_REFETCH_API } from "../constant/date";

//===========================>>>>
export const getCachingUserInLocalStorage = () => {
  const userCaching = localStorage.getItem("userCaching");

  if (isEmpty(userCaching)) return null;
  const created_at = JSON.parse(userCaching)?.created_at;
  if (fromNowDateWithLocal(created_at) >= DATE_REFETCH_API) {
    cleanCachingUserInLocalStorage();
    return null;
  } else {
    return JSON.parse(userCaching)?.userCaching;
  }
};

export const setCachingUserInLocalStorage = (userCaching) => {
  localStorage.setItem(
    "userCaching",
    JSON.stringify({ userCaching, created_at: newDateWithTimeZone() })
  );
};

export const cleanCachingUserInLocalStorage = () => {
  localStorage.removeItem("userCaching");
};
