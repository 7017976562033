import React, { useContext } from "react";
import { ArrowDownHeaderIcon } from "../../constant/icon";

import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";

export default function ButtonSignOut() {
  const { t } = useTranslation();
  const { user, signOut } = useContext(AuthContext);

  return (
    <>
      <Listbox>
        <div className="relative hidden mt-1 lg:block">
          <Listbox.Button className="flex items-center w-full gap-2 hover:cursor-pointer">
            <ArrowDownHeaderIcon />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-3 overflow-auto bg-white rounded-md shadow-lg -left-10 text-body-1 ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Listbox.Option
                onClick={signOut}
                className={
                  "relative hover:cursor-pointer hover:bg-primary-200 select-none hover:text-white text-fourth-600 justify-center items-center px-4 py-2"
                }
              >
                <p>{t("sign_out")}</p>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </>
  );
}
