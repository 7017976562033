import moment from "moment";

export const momentDateFormat = {
  LT: "LT",
  ymd: "MMMM Do YYYY",
};

export const isBeforeDateWithLocal = (date) => {
  var localDate = new Date();
  const localDateTimezone = moment.utc(localDate).format();

  const localDateTimezoneWithMoment = moment(localDateTimezone);

  const startOpenDate = moment(date);

  return moment(startOpenDate).isBefore(localDateTimezoneWithMoment);
};

export const fromNowDateWithLocal = (date) => {
  var localDate = new Date();
  const localDateTimezone = moment.utc(localDate).format();
  const localDateTimezoneWithMoment = moment(localDateTimezone);
  const startOpenDate = moment(date);

  var duration = moment.duration(
    localDateTimezoneWithMoment.diff(startOpenDate)
  );
  return duration.asSeconds();
};

export const newDateWithTimeZone = () => {
  var localDate = new Date();
  const localDateTimezone = moment.utc(localDate).format();
  const localDateTimezoneWithMoment = moment(localDateTimezone);
  return localDateTimezoneWithMoment;
};

export const buildTimeRangeFilter = (dataRange) => {
  if (!dataRange?.startDate || !dataRange?.endDate) {
    return null;
  }
  const startTime = moment.utc(new Date(dataRange?.startDate));
  let endTime = moment.utc(new Date(dataRange?.endDate));
  return [
    moment.parseZone(startTime.startOf("day").format()).utc().format(),
    moment.parseZone(endTime.endOf("day").format()).utc().format(),
  ];
  // const now = moment.utc(new Date());
  // let tomorrow = moment.utc().add(1, "days");
  // switch (type) {
  //   case "all":
  //     return null;
  //   case "today":
  //     return [
  //       moment.parseZone(now.startOf("day").format()).utc().format(),
  //       moment.parseZone(now.endOf("day").format()).utc().format(),
  //     ];
  //   case "tomorrow":
  //     return [
  //       moment.parseZone(tomorrow.startOf("day").format()).utc().format(),
  //       moment.parseZone(tomorrow.endOf("day").format()).utc().format(),
  //     ];

  //   default: {
  //     if (isArray(dataRange)) {
  //       const startTime = moment.utc(new Date(dataRange[0])).add(1, "days");
  //       const endTime = moment.utc(new Date(dataRange[1]));

  //       return [
  //         moment.parseZone(startTime.startOf("day").format()).utc().format(),
  //         moment.parseZone(endTime.endOf("day").format()).utc().format(),
  //       ];
  //     }
  //     return ["", ""];
  //   }
  // }
};
