import axiosInstance from "./api_instance";

export const getUserApi = () => {
  return axiosInstance.get("client/users/profile");
};

export const updateUserApi = ({
  fullName,
  nationality,
  city,
  email,
  address,
  avatar,
}) => {
  return axiosInstance.patch(`client/users/profile`, {
    email,
    fullName,
    nationality,
    city,
    address,
    avatar,
  });
};

export const getSettingPaymentApi = () => {
  return axiosInstance.get("api/setting");
};
