import { isEmpty } from "lodash";

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const setToken = (accessToken) => {
  localStorage.setItem("accessToken", JSON.stringify({ accessToken }));
};

export const cleanToken = () => {
  localStorage.removeItem("accessToken");
};
//===========================>>>>
export const getUserInLocalStorage = () => {
  const user = localStorage.getItem("user");

  if (isEmpty(user)) return null;
  return JSON.parse(user);
};

export const setUserInLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify({ user }));
};

export const cleanUserInLocalStorage = () => {
  localStorage.removeItem("user");
};
